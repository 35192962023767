import React, { Children, FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const CitationSC = styled.p`
  font-family: 'Georgia', serif;
  text-align: center !important;
  font-style: italic;
  font-size: 18px;
  line-height: 165%;
  width: 90%;
  margin: 3rem auto 4.5rem auto;

  &.small {
    font-size: 16px;
    margin: 1.5rem auto 1.5rem auto;
  }
`;

const Citation: FC<{ children?: ReactNode }> = function ({
  children,
  ...others
}) {
  return <CitationSC {...others}>{children}</CitationSC>;
};

export default Citation;
