import { CommandeModel, ProduitModel } from '@innedit/innedit-react';
import { CommandeType, ProduitNode } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TableSC = styled.table`
  width: 100%;

  td {
    border-bottom: 1px solid #f6f6f6;
    padding: 0.75rem 0;
    vertical-align: middle;

    &.text-center {
      text-align: center;
    }
    &.text-right {
      text-align: right;
    }
  }

  .thumbnail {
    float: left;
    width: 70px;
    margin-right: 0.75rem;
    display: ${props => (props.theme.mobile ? 'none' : 'block')};
  }

  .name,
  .id {
    display: block;
  }

  .sku {
    font-style: italic;
    font-size: 80%;
  }

  .retailPrice {
    //display: block;
    text-decoration: line-through;
    font-style: italic;
    font-size: 90%;
    font-family: 'Georgia', serif;

    &:after {
      content: ' €';
    }

    //margin-right: 0.75rem;
  }

  .price {
    display: block;
  }

  .price,
  .product-subtotal {
    font-family: 'Georgia', serif;
    &:after {
      content: ' €';
    }
  }
`;

const CommandeProduits: FC<{ commande: CommandeType }> = function ({
  commande: { deliveryAmount, deliveryMethod, produits: items },
}) {
  const { t } = useTranslation();
  const produitsAmount = CommandeModel.calculateProduitsAmount(items);
  const total =
    produitsAmount + ('carrier' === deliveryMethod ? deliveryAmount : 0);
  const [produits, setProduits] = useState<ProduitNode[]>();

  const itemsJSON = JSON.stringify(items);
  useEffect(() => {
    let isMounted = true;

    Promise.all(items.map(item => ProduitModel.get(item.id)))
      .then(values => {
        if (isMounted) {
          setProduits(values);
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [itemsJSON]);

  return (
    <TableSC className="max-w-screen-lg mx-auto">
      <thead>
        <tr>
          <th>{t('components.commande.produits.produit')}</th>
          <th>{t('components.commande.produits.quantite')}</th>
          <th>{t('components.commande.produits.prix')}</th>
          <th>{t('components.commande.produits.total')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => {
          const produit = produits?.find(p => p.id === item.id);

          return (
            <tr key={item.id}>
              <td className="produitInfos">
                {item.thumbnail && (
                  <img
                    alt={item.name}
                    className="thumbnail"
                    src={item.thumbnail}
                  />
                )}
                <strong className="name">{item.name}</strong>
                <span className="sku">{produit?.sku}</span>
                {/* {!produit.confirmedAt && !produit.canceledAt && ( */}
                {/*  <span className="block"> */}
                {/*    {t( */}
                {/*      'components.commande.produits.en-attente-de-confirmation', */}
                {/*    )} */}
                {/*  </span> */}
                {/* )} */}
                {/* {produit.canceledAt && ( */}
                {/*  <span className="block text-crimson-500"> */}
                {/*    {t('components.commande.produits.annule')} */}
                {/*  </span> */}
                {/* )} */}
                {/* {produit.confirmedAt && !produit.refundedAt && ( */}
                {/*  <span className="block text-cello-500"> */}
                {/*    {t('components.commande.produits.confirme')} */}
                {/*  </span> */}
                {/* )} */}
                {/* {produit.refundedAt && ( */}
                {/*  <span className="block text-crimson-500"> */}
                {/*    {t('components.commande.produits.rembourse')} */}
                {/*  </span> */}
                {/* )} */}
              </td>
              <td
                className={classnames('text-center', {
                  // 'line-through': produit.refundedAt || produit.canceledAt,
                })}
              >
                {Number(item.quantity)}
              </td>
              <td className="text-center">
                <span
                  className={classnames('price', {
                    // 'line-through': produit.refundedAt || produit.canceledAt,
                  })}
                >
                  {Number(item.price)}
                </span>
              </td>
              <td className="text-right">
                <span
                  className={classnames('product-subtotal', {
                    // 'line-through': produit.refundedAt || produit.canceledAt,
                  })}
                >
                  {Number(item.quantity) * Number(item.price)}
                </span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td className="text-right" colSpan={3}>
            {t('components.commande.produits.sous-total')}
          </td>
          <td className="text-right">{produitsAmount} €</td>
        </tr>
        {undefined !== deliveryAmount && (
          <tr>
            <td className="text-right" colSpan={3}>
              {t('components.commande.produits.livraison.label')}
            </td>
            {'store' === deliveryMethod && (
              <td className="text-right">
                {t('components.commande.produits.livraison.store')}
              </td>
            )}
            {'carrier' === deliveryMethod && (
              <td className="text-right">{deliveryAmount} €</td>
            )}
          </tr>
        )}
        <tr>
          <td className="text-right" colSpan={3}>
            {t('components.commande.produits.total')}
          </td>
          <td className="text-right">
            <strong>{total} €</strong>
          </td>
          {/* {undefined !== total && */}
          {/*  undefined !== amountAashed && */}
          {/*  amountAashed > 0 && */}
          {/*  amountAashed !== total && ( */}
          {/*    <td className="text-right"> */}
          {/*      <span className="line-through mr-2">{total} €</span> */}
          {/*      <strong>{amountAashed} €</strong> */}
          {/*    </td> */}
          {/*  )} */}
        </tr>
      </tbody>
    </TableSC>
  );
};

export default CommandeProduits;
