import { CommandeType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../Content';
import CommandeCoordonnees from './Coordonnees';
import CommandeProduits from './Produits';

const CommandeRecapitulatif: FC<{
  commande: CommandeType;
}> = ({
  commande,
  commande: { reference, confirmedAt, paidAt, validatedAt, status },
}) => {
  const { t } = useTranslation();

  return (
    <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
      <p className="text-center flex space-x-2 justify-center">
        {'confirmed' === status && (
          <span>
            {t('components.commande.recapitulatif.confirme', {
              reference,
              date: dayjs(confirmedAt).format('DD/MM/YYYY à HH:mm'),
            })}
          </span>
        )}

        {'paid' === status && (
          <span>
            {t('components.commande.recapitulatif.paye', {
              reference,
              date: dayjs(paidAt).format('DD/MM/YYYY à HH:mm'),
            })}
          </span>
        )}
      </p>
      {validatedAt && (
        <p className="italic font-bold text-center mb-6">
          {dayjs(validatedAt).format('dddd Do MMMM YYYY à H:mm')}
        </p>
      )}

      <h3 className="text-center text-2xl mt-12 mb-6">
        {t('components.commande.recapitulatif.vos-produits')}
      </h3>
      <CommandeProduits commande={commande} />
      <div className="flex space-x-12 max-w-screen-md mx-auto mt-24">
        <CommandeCoordonnees commande={commande} />
      </div>
    </Content>
  );
};

export default CommandeRecapitulatif;
