import { CommandeType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CommandeCoordonneesSC = styled.div`
  span {
    display: block;
  }
`;

const CommandeCoordonnees: FC<{ commande: CommandeType }> = ({
  commande: {
    contactAddress,
    contactFirstName,
    contactCity,
    contactEmail,
    contactLastName,
    contactPhone,
    contactZip,
  },
}) => {
  const { t } = useTranslation();

  return (
    <CommandeCoordonneesSC className="flex-1">
      <h3 className="text-2xl mb-6">
        {t('components.commande.coordonnees.vos-coordonnees')}
      </h3>
      <span>
        {contactFirstName} {contactLastName}
      </span>
      <span>{contactAddress}</span>
      <span>
        {contactZip} {contactCity}
      </span>
      <span>{contactEmail}</span>
      <span>{contactPhone}</span>
    </CommandeCoordonneesSC>
  );
};

export default CommandeCoordonnees;
